/* eslint-disable camelcase */
import { http } from '../http-common'

class ManualShipmentService {
  /*
    ManualShipmentService handles uploading shipments via file
  */

  getSettings () {
    return http.request({
      method: 'GET',
      url: '/shipments/files/settings/'
    })
  }

  listUploadedFiles () {
    return http.request({
      method: 'GET',
      url: '/shipments/files/'
    })
  }

  /**
   * Generate presigned URLs for uploading shipment files to S3
   * @param {Array.<string>} filenames A list of filenames
   * @returns {object} An object containing the presigned URLs and other information needed to upload the files to S3
   */
  #generateUploadUrls (filenames) {
    return http.request({
      method: 'POST',
      url: '/shipments/generate_upload_urls_for_shipments_files/',
      data: { filenames }
    })
  }

  /**
   * Upload a file to S3 using a presigned URL
   * @param {FormData} filedata Data to upload
   * @param {string} url A presigned URL to upload to
   * @param {number} [retries=5] retries Number of times to retry uploading
   * @returns {bool} True if the file was uploaded successfully, false otherwise
   */
  async #uploadUsingS3Url (filedata, url, retries = 5) {
    let tries = 0
    while (tries < retries) {
      const res = await fetch(url, {
        method: 'POST',
        body: filedata
      })
      if (res.status === 204) {
        return true
      } else {
        tries++
      }
    }
    return false
  }

  /**
   * Upload one or more files to S3 using presigned URLs
   * @param {Map.<string, object>} informationNeededToUploadToS3
   * @returns {object} {result: bool, failed: string|null} result is true if all files were uploaded successfully, false otherwise.
   * failed is the filename of the file that failed to upload, or null if all files were uploaded successfully
   */
  async #uploadMultipleFilesUsingS3Urls (informationNeededToUploadToS3) {
    for (const [filename, data] of informationNeededToUploadToS3) {
      const uploadResult = await this.#uploadUsingS3Url(data.filedata, data.url)
      if (uploadResult === false) {
        return { result: uploadResult, failed: filename }
      }
    }
    return { result: true, failed: null }
  }

  /**
   * Convert a map into an object for using in a http.request
   * @param {Map.<string|number, any>} map
   * @returns {object}
   */
  #convertMapToObject (map) {
    return Object.fromEntries(Array.from(map.entries(), ([k, v]) => v instanceof Map ? [k, this.#convertMapToObject(v)] : [k, v]))
  }

  /**
   * Record the files that were uploaded to S3
   * @param {string} mainFilename Name of the main file that was uploaded
   * @param {string} mainFileS3Key S3 key of the main file that was uploaded
   * @param {Map} additionalData Additional data to record such as the filenames and s3 keys of extra files
   * @returns
   */
  #recordUploadedFiles (mainFilename, mainFileS3Key, additionalData = new Map()) {
    return http.request({
      method: 'POST',
      url: '/shipments/files/',
      data: {
        s3_key: mainFileS3Key,
        filename: mainFilename,
        additional_data: this.#convertMapToObject(additionalData)
      }
    })
  }

  /**
   * Generate presigned URLs and upload files to S3
   * @param {FileList|Array.<File>} files A list of files to upload
   * @returns {Map.<string, string>|bool} A map of filenames to S3 keys if all files were uploaded successfully, false otherwise
   */
  async #uploadFilesToS3 (files) {
    const filenames = []
    for (const file of files) {
      filenames.push(file.name)
    }
    const filenamesToS3Keys = new Map()
    const informationNeededToUploadToS3 = new Map()
    try {
      const urlsResponse = await this.#generateUploadUrls(filenames)
      for (const filename of filenames) {
        const fileData = urlsResponse.data[filename]
        const fields = fileData.fields
        const s3Data = new FormData()
        Object.keys(fields).forEach(key => {
          s3Data.append(key, fields[key])
        })
        s3Data.append('file', files.find(file => file.name === filename))
        informationNeededToUploadToS3.set(filename, { filedata: s3Data, url: fileData.url })
        filenamesToS3Keys.set(filename, fields.key)
      }
    } catch (e) {
      return false
    }
    try {
      const uploadResult = await this.#uploadMultipleFilesUsingS3Urls(informationNeededToUploadToS3)
      if (uploadResult.result === false) {
        return false
      }
    } catch (e) {
      return false
    }
    return filenamesToS3Keys
  }

  /**
   * Upload shipment files to S3 and record the files that were uploaded
   * @param {FileList|Array.<File>} files A list of files to upload, the main file must be first
   * @param {Map.<string,any>} extraData Any additional data to record with the files,
   * excluding the original filename and file S3 key
   * @returns {bool} True if the files were uploaded successfully, false otherwise
   */
  async uploadShipmentFiles (files, extraData = new Map()) {
    const filenamesToS3Key = await this.#uploadFilesToS3(files)
    if (filenamesToS3Key === false) {
      return false
    }
    const mainFilename = files[0].name
    const mainFileS3Key = filenamesToS3Key.get(mainFilename)
    const formatedExtraData = new Map()
    if (files.length > 1) {
      for (let i = 1; i < files.length; i++) {
        const extraFileDataKey = `extra_file_${i}`
        formatedExtraData.set(extraFileDataKey, new Map([
          ['original_filename', files[i].name],
          ['file_s3_key', filenamesToS3Key.get(files[i].name)]
        ]))
        if (extraData.has(extraFileDataKey)) {
          extraData[extraFileDataKey].delete('original_filename')
          extraData[extraFileDataKey].delete('file_s3_key')
          extraData[extraFileDataKey].forEach((value, key) => formatedExtraData.set(key, value))
        }
      }
    }
    if (extraData.has('additional_data')) {
      formatedExtraData.set('additional_data', extraData.get('additional_data'))
    }
    try {
      const recordResult = await this.#recordUploadedFiles(mainFilename, mainFileS3Key, formatedExtraData)
      if (recordResult.status !== 200) {
        return false
      }
    } catch (e) {
      return false
    }
    return true
  }
}

export default new ManualShipmentService()
