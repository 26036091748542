import { createPinia } from 'pinia'
import PrimeVue from 'primevue/config'
import Aura from '@primevue/themes/aura'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import titleMixin from './mixins/titleMixin.js'
import './assets/css/theme.css'
import Hotjar from 'vue-hotjar'
import * as Sentry from '@sentry/vue'
import {
  ExtraErrorData as ExtraErrorDataIntegration,
  HttpClient as HttpClientIntegration,
  ContextLines
} from '@sentry/integrations'
import VueApexCharts from 'vue3-apexcharts'

const isProduction = String(process.env.VUE_APP_ENVIRONMENT).toLowerCase() === 'production'
/* global $ */
const pinia = createPinia()
const app = createApp(App)
app.use(pinia)
app.use(VueApexCharts)
app.use(PrimeVue, {
  theme: {
    preset: Aura
  }
})

// Setting up Sentry
if (['production', 'staging', 'demo'].includes(String(process.env.VUE_APP_ENVIRONMENT).toLowerCase())) {
  Sentry.init({
    app,
    dsn: String(process.env.VUE_APP_SENTRY_DSN),
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [String(process.env.VUE_APP_BASE_URL)],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),
      new Sentry.Replay(),
      new ExtraErrorDataIntegration(),
      new HttpClientIntegration(),
      new ContextLines()
    ],
    environment: String(process.env.VUE_APP_ENVIRONMENT).toLowerCase(),
    debug: String(process.env.VUE_APP_ENVIRONMENT).toLowerCase() === 'staging',
    sampleRate: Number(process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE),
    attachStacktrace: true,
    autoSessionTracking: true,

    // Performance Monitoring
    tracesSampleRate: Number(process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE), // This should be lower in production (or when we get more traffic in production)

    // Session Replay
    replaysSessionSampleRate: 0.0, // This replays the whole session. Let's remove it for now.
    replaysOnErrorSampleRate: Number(process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE) // Always record a session when there's an error
  })
}

app.use(router)
  .use(Hotjar, {
    id: process.env.VUE_APP_HOTJAR_APP_ID,
    isProduction
  })
  .mixin(titleMixin)

// Tooltip handler
$(function () {
  $('body').tooltip({
    selector: '[data-toggle="tooltip"]'
  })
})

app.mount('#app')
