<script setup>
import ManualShipmentService from '@/services/ManualShipmentService.js'
import TheModal from '@/components/commons/TheModal.vue'
import { ref, computed } from 'vue'
import Select from 'primevue/select'
const props = defineProps({
  fileSizeLimit: {
    type: Number,
    default: 20
  },
  fileSizeUnit: {
    type: String,
    default: 'MB'
  },
  supportedFileTypes: {
    type: Array,
    default: () => ['csv']
  },
  shipmentTypes: {
    type: Array,
    default: () => []
  }
})

// File data
const file = ref(undefined)
const filename = ref(undefined)
const fileUploaded = ref(false)
const uploading = ref(false)
const selectedShipmentType = ref(undefined)

// Validation
const numberOfFilesOk = ref(undefined)
const fileSizeOk = ref(undefined)
const fileTypeOk = ref(undefined)
const mistakes = ref([])

const showModal = ref(false)
const dropZoneActive = ref(false)

const resetFileData = () => {
  file.value = undefined
  filename.value = undefined
  fileUploaded.value = false
  uploading.value = false
}

const resetValidationData = () => {
  numberOfFilesOk.value = undefined
  fileSizeOk.value = undefined
  fileTypeOk.value = undefined
  mistakes.value = []
}

const validateFiles = (files) => {
  resetValidationData()
  if (files.length !== 1) {
    numberOfFilesOk.value = false
    mistakes.value.push('You can only upload one file at a time')
    return
  } else {
    numberOfFilesOk.value = true
  }
  file.value = files[0]
  filename.value = file.value.name
  // Convert from bytes to the unit of FileSizeLimit
  let fileSize = file.value.size
  if (props.fileSizeUnit === 'MB') {
    fileSize = fileSize / (1024 * 1024)
  } else if (props.fileSizeUnit === 'KB') {
    fileSize = fileSize / 1024
  }
  if (fileSize > props.fileSizeLimit) {
    fileSizeOk.value = false
    mistakes.value.push(`File too large. Max file size is ${props.fileSizeLimit} ${props.fileSizeUnit}`)
  }
  const fileExtension = file.value.name.split('.').pop().toLowerCase()
  if (!props.supportedFileTypes.includes(fileExtension)) {
    fileTypeOk.value = false
    mistakes.value.push(`File type is not supported. Supported file types are ${props.supportedFileTypes.join(', ')}`)
  }
}

const uploadFile = async (files) => {
  validateFiles(files)
  if (mistakes.value.length > 0) {
    showModal.value = true
    return
  }
  const extraData = new Map()
  if (requiresShipmentType.value) {
    extraData.set('additional_data', { shipment_type: selectedShipmentType.value })
  }
  uploading.value = true
  fileUploaded.value = await ManualShipmentService.uploadShipmentFiles([file.value], extraData)
  uploading.value = false
  showModal.value = true
}

const handleModalClose = () => {
  showModal.value = false
  resetFileData()
  resetValidationData()
}

const handleDropEvent = (event) => {
  event.preventDefault()
  uploadFile(event.dataTransfer.files)
  dropZoneActive.value = false
}

const toggleDropActive = () => {
  dropZoneActive.value = !dropZoneActive.value
}

const requiresShipmentType = computed(() => {
  return props.shipmentTypes.length > 0
})

const shipmentTypeOK = computed(() => {
  return selectedShipmentType.value !== undefined || !requiresShipmentType.value
})
</script>

<template>
  <div class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-1/2">
    <Select
      v-if="requiresShipmentType"
      v-model="selectedShipmentType"
      :options="shipmentTypes"
      optionLabel="label"
      optionValue="value"
      placeholder="Select a Shipment Type"
      class="w-full md:w-56 tw-mb-4"
    />
    <div
    v-if="shipmentTypeOK"
    :class="[dropZoneActive ? 'tw-bg-anansi-light-gray ' : 'tw-bg-white',
            'tw-border-dashed tw-border-2 tw-rounded tw-p-5',
            'tw-flex tw-flex-col tw-justify-center tw-items-center',
            'tw-w-full']"
    @dragenter.prevent="toggleDropActive"
    @dragleave.prevent="toggleDropActive"
    @dragover.prevent
    @drop.prevent="handleDropEvent"
    >
      <div class="tw-flex tw-flex-row">
        <p class="tw-font-semibold tw-my-0 tw-pr-2">Drag and drop a file to upload or </p>
        <div class="tw-w-16 tw-h-7">
          <input
              type="file"
              :disabled="uploading"
              class="tw-flex tw-items-center tw-opacity-0 tw-absolute tw-z-50 tw-w-16 tw-h-7 tw-cursor-pointer"
              v-on:change="uploadFile($event.target.files)"
              accept="text/csv"
              data-test="file-upload"
          />
          <label class="tw-flex tw-items-center tw-underline">Browse</label>
        </div>
      </div>
      <p class="tw-font-light tw-text-sm tw-my-0">Supported file types: {{ supportedFileTypes.join(', ') }}</p>
      <p class="tw-font-light tw-text-sm tw-my-0">Max file size: {{ fileSizeLimit }} {{ fileSizeUnit }}</p>
    </div>
  </div>
  <TheModal :show="showModal" size='l' header_position='upload-center' body_position='upload-center' footer_position='center' @close="handleModalClose()" :custom_button_style="true">
    <template #header v-if="fileUploaded">
      <h3>📦   Your file was uploaded successfully!   🎉</h3>
    </template>
    <template #header v-else>
      <h3>❌  There was an error uploading the file  ❌</h3>
    </template>
    <template #body v-if="fileUploaded">
      <p style="font-size: 12px;">We're now processing "{{ filename }}"</p>
      <div class="row my-5">
        <p class="text-center">Please, remember that file processing can take a few hours. After that, you'll be able to see the shipments in your dashboard.</p>
      </div>
    </template>
    <template #body v-else>
      <div class="tw-flex tw-flex-col tw-items-center">
        <p>There was an error during your file upload. Please, try again.</p>
        <p v-for="mistake in mistakes" :key="mistake">{{ mistake }}</p>
      </div>
    </template>
  </TheModal>
</template>
