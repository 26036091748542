<template>
  <NavigationBar v-bind="{ uploads: true }" />
  <div class="container-fluid pt-7 flex-fill">
    <!-- Page Content  -->
    <div id="content" class="col" v-if="canUploadShipments == true">
      <div class="col-12 card shadow-light table-style upload-wrapper">
        <h1 data-test="shipment-upload-page-title">Uploads</h1>
        <p class="mb-0">
          Upload files containing shipments to insure.
          Please note it can take a few hours until they appear in the dashboard.
        </p>
        <div class="tw-flex tw-justify-center tw-items-center tw-my-6">
          <SingleFileUpload
            v-if="shipmentUploadFormat === 'SWE' || shipmentUploadFormat === 'ANANSI' || shipmentUploadFormat === 'DEX'"
            :fileSizeLimit="fileSizeLimit"
            :shipmentTypes="shipmentTypes"/>
          <TwoFileUpload v-if="shipmentUploadFormat === 'POSTX'" :fileSizeLimit="fileSizeLimit"/>
        </div>
        <div
          class="container p-0 text-left mb-4 underlined"
        >
          <h2 class="mb-1">Uploaded files</h2>
        </div>
        <div class="container mb-4" v-if="!loadingFiles && uploadedFiles && uploadedFiles.length">
          <div class="row p-2 font-weight-bold">
            <div class="col-sm-5 text-left">
              File name
            </div>
            <div class="col-sm-4">
              Upload date
            </div>
            <div class="col-sm-3">
              Status
            </div>
          </div>
          <div
            v-for="(uFile, idx) in uploadedFiles"
            :key="idx"
            class="row shadow-light p-2 mb-3 file-card"
          >
            <div class="col-sm-5 text-left">
              {{ uFile.filename }}
            </div>
            <div class="col-sm-4">
              {{ this.formatDate(uFile.created_date) }} {{ this.formatTime(uFile.created_date) }}
            </div>
            <div class="col-sm-3">
              {{ uFile.status }}
            </div>
          </div>
        </div>
        <div class="container mb-4 p-2 text-center message"
          v-else-if="!loadingFiles && !loadingFilesError"
        >
          There are no files to show. Files that you upload will show in here.
        </div>
        <div class="container mb-4 p-2 text-center message error-message"
          v-else-if="loadingFilesError"
        >
          There was an error while trying to load uploaded files.
          Please try again later.
        </div>

        <div class="justify-content-center row" v-else>
          <div class="col-6">
            <div class="d-flex mt-4 justify-content-center resource-tables-empty-box">
            <span class="mr-3">
              <i class="fa fa-spinner fa-spin gold-text font-1-3-rem" />
            </span>
            <p>Fetching data...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MainFooter />
</template>

<script>

import NavigationBar from '@/components/NavigationBar.vue'
import MainFooter from '@/components/MainFooter.vue'
import ManualShipmentService from '@/services/ManualShipmentService.js'
import SingleFileUpload from '@/components/shipment-file-upload/SingleFileUpload.vue'
import TwoFileUpload from '@/components/shipment-file-upload/TwoFileUpload.vue'

export default {
  name: 'UploadsView',
  title: 'Anansi - Uploads',
  components: {
    NavigationBar,
    MainFooter,
    SingleFileUpload,
    TwoFileUpload
  },
  data () {
    return {
      canUploadShipments: undefined,
      shipmentUploadFormat: undefined,
      shipmentTypes: [],
      uploadedFiles: [],
      loadingFiles: true,
      loadingFilesError: '',
      fileSizeLimit: parseInt(process.env.VUE_APP_SHIPMENT_FILE_SIZE_LIMIT_MB)
    }
  },
  async beforeMount () {
    try {
      const response = await ManualShipmentService.getSettings()
      this.canUploadShipments = response.data.can_upload
      this.shipmentUploadFormat = response.data.file_format
      if (this.shipmentUploadFormat) {
        this.shipmentUploadFormat = this.shipmentUploadFormat.toUpperCase()
      }
      if (!response.data.can_upload) return this.$router.back()
      this.shipmentTypes = this.getShipmentTypeAndValue(response.data.available_shipment_types)
    } catch (error) {
      console.log('Failed to get shipment file upload settings: ' + error)
      this.canUploadShipments = false
      this.$router.back()
    }
    try {
      const response = await ManualShipmentService.listUploadedFiles()
      this.uploadedFiles = response.data
      this.loadingFiles = false
    } catch (error) {
      const errorMsg = 'Failed to list uploaded files: ' + error
      console.log(errorMsg)
      this.loadingFilesError = errorMsg
      this.loadingFiles = false
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return
      const d = new Date(date)
      let month = '' + (d.getMonth() + 1)
      let day = '' + d.getDate()
      const year = d.getFullYear()

      if (month.length < 2) { month = '0' + month }
      if (day.length < 2) { day = '0' + day }

      return [year, month, day].join('-')
    },
    formatTime (dateISO) {
      return dateISO.slice(11, 16)
    },
    getShipmentTypeAndValue (shipmentTypes) {
      return shipmentTypes.map((shipmentType) => {
        return {
          value: shipmentType,
          label: this.getShipmentTypeLabel(shipmentType)
        }
      })
    },
    getShipmentTypeLabel (shipmentType) {
      const valueToLabel = {
        INBOUND: 'Inbound',
        OUTBOUND: 'Outbound'
      }
      return valueToLabel[shipmentType]
    }
  }
}
</script>

<style scoped>
  .upload-wrapper {
    padding: 1.5rem !important;
    text-align: center;
  }

  .font-1-3-rem {
    font-size: 1.3rem;
  }

  .underlined {
    border-bottom: 2px solid LightGrey;
  }

  .file-card {
    border: solid 2px LightGray;
  }

  .message {
    border: dashed 2px;
    border-radius: 6px;
    background-color: #f9fbfd;
  }

  .error-message {
    border-color: #f44336;
    background-color: #F99B94;
  }

  @media screen and (min-width: 920px) {
    .upload-wrapper {
      padding: 3rem !important;
    }
  }
</style>
